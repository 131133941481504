.inactive {
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
}
@media (min-width: 768px) {
  .inactive {
    color: #ffffff;
    font-weight: 400;
    font-size: 20px;
    padding: 8.5px 14px;
    line-height: 130%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.active {
  /* width: calc(100% / 3); */
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  /* padding: 10px 14px; */
  height: 37px;
  line-height: 140%;
  color: black;
  background: #e4f53b;
  border-radius: 6px;
  box-sizing: border-box;
  flex-grow: 1;
}
@media screen and (min-width: 768px) {
  .active {
    height: 45px;
    color: #051224 !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    padding: 8.5px 14px;
    height: 100%;
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    fill: white;
    background: transparent;
  }
}
